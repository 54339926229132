import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '../../core/services/i18n.service';
import { InfoRemoteService } from 'src/app/services/info-remote.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Language } from 'src/app/models/language';

/** Componente para la página de información. */
@Component({
  selector: 'app-information-layout',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InformationPageComponent implements OnInit {

  /** Opción por defecto */
  currentOption = null;
  /** Revisa si es resolución móvil */
  mobile = true;
  /** Opciones */
  options = [];
  /** Textos */
  text: any;
  /** idioma seleccionado */
  languageSelected: Language;
  /** Variable para la búsqueda */
  research: boolean;

  /**
   * Constructor de la clase.
   * @ignore
   */
  constructor(private router: Router,
    private i18nService: I18nService,
    private infoRemoteService: InfoRemoteService,
    private loaderService: LoaderService,
    private sanitizer: DomSanitizer) {
    this.options = [
      {
        url: '/terms-of-use',
        title: this.i18nService.getText('termsOfUse'),
        imageWhite: '/assets/images/information/mobile/white/usage-conditions.png',
        imageBlue: '/assets/images/information/mobile/blue/usage-conditions.png'
      },
      {
        url: '/privacy-policy',
        title: this.i18nService.getText('privacyPolicy'),
        imageWhite: '/assets/images/information/mobile/white/privacy-policy.png',
        imageBlue: '/assets/images/information/mobile/blue/privacy-policy.png'
      },
      {
        url: '/cookie-policy',
        title: this.i18nService.getText('cookiePolicies'),
        imageWhite: '/assets/images/information/mobile/white/cookies-policy.png',
        imageBlue: '/assets/images/information/mobile/blue/cookies-policy.png'
      },
      {
        url: '/research-development',
        title: this.i18nService.getText('projectIplusD'),
        imageWhite: '/assets/images/information/mobile/white/imasd-logo.svg',
        imageBlue: '/assets/images/information/mobile/blue/imasd-logo.svg'
      },
    ];
  }

  /**
   * Método que se ejecuta al iniciar el componente.
   */
  ngOnInit() {
    this.loaderService.showLoader();
    this.languageSelected = this.i18nService.getCurrentLanguage();
    if (this.languageSelected.code === 'en') {
      this.options.pop();
      if (this.router.url === '/cookie-policy') {
        this.router.navigate(['/terms-of-use']);
      }
    }

    this.currentOption = this.options.find(option => option.url === this.router.url);
    this.infoRemoteService.get(this.currentOption.url).subscribe(info => {
      this.text = this.sanitizer.bypassSecurityTrustHtml(info.text);
      this.loaderService.hideLoader();
    }, () => {
      this.text = '';
      this.loaderService.hideLoader();
    });

    if (this.router.url === '/research-development') {
      this.research = true;
    }
  }

  /**
   * Método que se ejecuta al cambiar de idioma.
   * @param option
   */
  changeInfo(option) {
    this.router.navigateByUrl(option.url);
  }

}
