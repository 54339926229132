// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/**
 * @ignore
 */
export const environment = {
  production: false,
  // apiUrl: 'https://test-2.api.pruebas.ecapturedtech.com',
  //apiUrl: 'https://dev.api.bosqueurbano.ecapturedtech.com',
  // pwUrl: 'https://bosqueurbano.ecapturedtech.com',
  // apiUrl: "http://localhost/Bosque-Urbano-API/public",
  apiUrl: 'https://api.elania.app',
  pwUrl: 'https://elania.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
